import React from "react";
import './style.scss';
import { image } from "../../../Assets";

const Footer = () =>{
    return(<>
        <footer>
            <div className="container main-footer">
                <div className="row">
                    <div className="col-12">
                        <p className="disclosure">
                            Disclosure of Interests:<br/>
                        “ All securities offered by Mailbox Money RE and its affiliates are made pursuant to an exemption from registration under the Securities Act of 1933. The Securities offered are only done so to certain qualified investors through the offering documents and may involve certain risk factors as discussed therein. Nothing on this website can be constituted to be an offer or sale of securities. We make no representation or guarantee as to the success of any investment. Investors should always consult a qualified financial professional before making any investment decisions."
                        </p>
                    </div>
                    <div className="col-lg-2 col-4 logo">
                        <img src={image.logo} alt="" />
                    </div>
                    <div className="col-lg-8 col-6 menu-wrapper d-flex align-items-center justify-content-center">
                        <div className="menu">
                            <ul> 
                                <li><a href="/investor">Investor</a></li>
                                <li><a href="/about">About</a></li>
                                <li><a href="/portfolio">Portfolio</a></li>
                                <li><a href="/media">Media</a></li>
                                <li><a href="/contact">Contact</a></li>
                                <li><a href="/podcast">Podcast</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-2 linkedin">
                        <a href="https://www.linkedin.com/in/dusten-hendrickson" target="_blank"><img src={image.linkedin} alt="" /></a>
                    </div>
                </div>
            </div>
            <div className="container copyright">
                <div className="row">
                    <div className="col-12">
                        <span>© Mailbox Money 2022. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </footer>
    </>)
}

export default Footer;